<template>
  <el-dialog
    :visible.sync="showDialog"
    width="448px"
    class="setting__dialog"
    :before-close="handleDialogClose"
    :close-on-click-modal="false"
    :class="!needToVerifyThePassword ? componentName : ''"
    :destroy-on-close="true"
  >
    <div slot="title">
      <div>{{ dialogTitle }}</div>
      <div
        class="setNewPasswordTips"
        v-if="dialogTitle === $t('setNewLoginPassword')"
      >
        {{ $t("setPasswordTips") }}
      </div>
    </div>
    <!-- 只有当账户登录状态是melinked账号登录或者是第三方登录但是设置了强密码的时候，才会验证登录密码 -->
    <checkPwd v-if="showCheckPassWordView"></checkPwd>
    <component v-else :is="componentName" :ref="componentName"></component>
  </el-dialog>
</template>
<script>
import password from "./components/password";
import phone from "./components/phone";
import email from "./components/email";
import question from "./components/question";
import checkPwd from "./components/checkPassword";
import setNewPassword from "./components/setNewPassword";
import selectionMethod from "./components/selectionMethod.vue";
import paymentPassword from "./components/paymentPassword.vue";
export default {
  name: "accountSecurityDialog",
  components: {
    password,
    phone,
    email,
    question,
    checkPwd,
    setNewPassword,
    selectionMethod,
    paymentPassword,
  },
  watch: {
    componentName: {
      handler(val) {
        // 如果没有验证过登录密码，则需要验证登录密码
        this.$store.commit(
          "setNeedToVerifyThePassword",
          this.accountSecurity.recordTheVerifiedPassword === "" ? true : false
        );
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 展示验证登录密码窗口
    showCheckPassWordView() {
      return (
        this.showDialog &&
        this.needToVerifyThePassword &&
        (this.loginState === "melinkedLogin" ||
          (this.loginState === "thirdPartyLogin" &&
            this.secureInfo.hasLoginStrongPwd))
      );
    },
    loginState() {
      return this.$store.getters.userInfo.loginState;
    },
    secureInfo() {
      return this.$store.state.accountStore.secureInfo;
    },
    accountSecurity() {
      return this.$store.state.accountSecurity;
    },
    // 弹窗标题
    dialogTitle() {
      let dialogTitle = "";
      if (this.showCheckPassWordView) {
        dialogTitle = this.$t("checkPwd");
      } else {
        switch (this.componentName) {
          default:
            break;
          case "selectionMethod":
            dialogTitle = this.$t("selectBindingMethod");
            break;
          case "phone":
            dialogTitle =
              this.filterTitle("phone") || this.$t("verifyPhoneNumber");
            break;
          case "email":
            dialogTitle = this.filterTitle("email") || this.$t("bindMailbox");
            break;
          case "question":
            dialogTitle =
              this.filterTitle("question") || this.$t("bindingSecurityIssues");
            break;
          case "password":
            dialogTitle =
              this.filterTitle("password") || this.$t("setNewLoginPassword");
            break;
          case "paymentPassword":
            dialogTitle = this.$t("setPaymentPassword");
            break;
        }
      }
      if (this.componentName === "setNewPassword") {
        dialogTitle =
          this.filterTitle("setNewPassword") || this.$t("setNewPwd"); //设置登录密码
      }
      return dialogTitle;
    },
    // 展示的组件名
    componentName() {
      return this.accountSecurity.setNeedShowAccountSecurityComponentName;
    },
    // 弹窗的状态
    showDialog() {
      return this.accountSecurity.accountSecurityDialogStatus;
    },
    // 是否需要验证登录密码
    needToVerifyThePassword() {
      return this.accountSecurity.needToVerifyThePassword;
    },
    // 当前是在修改已绑定的邮箱账号安全信息
    currentModificationEmailAccount() {
      return this.$store.state.melinked_email.currentModificationEmailAccount;
    },
  },
  methods: {
    // 如何监听右上角 x 关闭按钮
    handleDialogClose() {
      this.$store.commit("setCurrentModificationEmailAccount", null);
      this.$store.commit("setAccountSecurityDialogStatus", false);
      this.$store.commit("setNeedShowAccountSecurityComponentName", "");
    },
    // 弹窗标题设置
    filterTitle(type) {
      let title = "";
      switch (type) {
        case "password":
          title = this.$t("setNewLoginPassword");
          break;
        case "phone":
          title = this.secureInfo.securePhone
            ? this.$t("verification") + this.$t("phone")
            : this.$t("bind") + this.$t("phone");
          break;
        case "email":
          title = this.secureInfo.secureEmail
            ? this.$t("verification") + this.$t("email")
            : this.$t("bind") + this.$t("email");
          break;
        case "question":
          title = this.secureInfo.hasSecureQuestion
            ? this.$t("verification") + this.$t("secureQuestion")
            : this.$t("bind") + this.$t("secureQuestion");
          break;
        case "setNewPassword":
          title = this.$t("setNewPwd");
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="stylus">
.setting__dialog
  .el-dialog
    margin-top 0 !important;
    padding 30px 40px !important;
    .el-dialog__header
      padding 30px 0 !important;
      .setNewPasswordTips
        font-size 12px;
        color #8F8F8F;
        margin-top 14px;
        text-align left;
        word-break: keep-all;
      .el-dialog__title
        color #343434 !important;
    .el-dialog__body
      padding 0 !important;
</style>
