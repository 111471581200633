<template>
  <div class="question__setting">
    <div class="inputAreaBox" v-if="hasSetUpSecurityQA">
      <div
        class="inputOuterStyle"
        v-for="item in secureQuestionAnswers"
        :key="item.questionId"
      >
        <QAInput :questionItem="item" @getAnswer="getAnswer"></QAInput>
      </div>
      <div
        class="nextStepButton"
        :class="allowedNextStep ? 'commomButtonHover' : ''"
        @click.stop="startNextStep"
      >
        <span class="iconfont_Me icon-next iconStyle"></span>
      </div>
    </div>
    <div class="inputAreaBox" v-else>
      <div
        class="inputOuterStyle"
        v-for="(item, index) in setUpSecurityQuestions"
        :key="index"
      >
        <QuestionListPopover
          :handlerQuestionsList="listOfQuestions"
          v-if="item.showSelecter"
          :selecterIndex="index"
          @selectQuestion="selectQuestion"
        >
          <div class="questionSelecter">
            <div class="selecterLeft">
              <div class="questionIcon">
                <el-image
                  style="width: 18px; height: 18px"
                  :src="
                    require('@/assets/images/newLogin/questionIconLarge.png')
                  "
                  fit="fill"
                ></el-image>
              </div>
              <div class="questionText">
                {{ $t("setAnswer") }}
              </div>
            </div>
            <div class="selecterRight">
              <span class="iconfont_Me icon-down iconStyle"></span>
            </div>
          </div>
        </QuestionListPopover>
        <QAInput
          v-if="!item.showSelecter"
          :needCloseQuestion="true"
          :questionItem="item.secureQA"
          :autoFocus="!item.showSelecter"
          :indexInParent="index"
          @getAnswer="getNewAnswer"
          @closeQuestionBox="closeQuestionBox"
        ></QAInput>
      </div>
      <div
        class="nextStepButton"
        :class="allowedSetUpNewQA ? 'commomButtonHover' : ''"
        @click.stop="startSetUpNewQA"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import QAInput from "@/newVersion/components/inputComponent/QAInput";
import QuestionListPopover from "@/newVersion/components/questionListPopover";
import { checkAnswers, modifyQuestion, getSecuresQuestions } from "../api";
import Bus from "@/utils/bus.js";
export default {
  name: "Question",
  data() {
    return {
      // 已设置过的安全问题列表
      secureQuestionAnswers: [],
      // 系统默认的全部问题列表
      listOfQuestions: [],
      oldData: {},
      // 设置过安全问题，需要先验证安全问题
      hasSetUpSecurityQA: false,
      // 用于记录是否验证过旧手机号，如果验证过旧手机号，需要传入接口需要传入旧手机号
      verifiedOldQA: false,
      // 设置安全问答的列表
      setUpSecurityQuestions: [
        {
          secureQA: {},
          showSelecter: true,
        },
        {
          secureQA: {},
          showSelecter: true,
        },
        {
          secureQA: {},
          showSelecter: true,
        },
      ],
    };
  },
  components: {
    QAInput,
    QuestionListPopover,
  },
  async created() {
    // 请求全部系统问题队列
    await this.handlerGetQuestiongList();
    // 获取所有的问题队列
    this.listOfQuestions = _.cloneDeep(
      this.$store.state.accountStore.secureInfo.allSecureQuestionAnswers
    );
    // 获取是否设置过安全问题
    this.hasSetUpSecurityQA = this.$store.state.accountStore.secureInfo.hasSecureQuestion;
    // 根据安全问题状态判断是否需要装填默认值
    if (this.hasSetUpSecurityQA) {
      // 将已设置过的问题存储起来
      this.secureQuestionAnswers = _.cloneDeep(
        this.$store.state.accountStore.secureInfo.secureQuestionAnswers
      );
      this.listOfQuestions = this.listOfQuestions.map((el) => {
        switch (el.id) {
          case this.secureQuestionAnswers[0].questionId:
            el.disabled = true;
            break;
          case this.secureQuestionAnswers[1].questionId:
            el.disabled = true;
            break;
          case this.secureQuestionAnswers[2].questionId:
            el.disabled = true;
            break;
          default:
            el.disabled = false;
            break;
        }
        return el;
      });
    }
  },
  computed: {
    // 验证安全问题，是否可以进入下一步设置安全问题
    allowedNextStep() {
      return this.secureQuestionAnswers.every((item) => item.answer !== "");
    },
    // 验证是否填写完成新的QA
    allowedSetUpNewQA() {
      return this.setUpSecurityQuestions.every(
        (item) => item.secureQA.answer && item.secureQA.answer !== ""
      );
    },
  },
  methods: {
    // 获取系统安全问题集合
    handlerGetQuestiongList() {
      return new Promise(async (resolve, reject) => {
        const result = await getSecuresQuestions();
        if (result && result.code === 200) {
          let secureInfo = _.cloneDeep(
            this.$store.state.accountStore.secureInfo
          );
          secureInfo.allSecureQuestionAnswers = result.data.data || [];
          this.$store.commit("setSecureInfo", secureInfo);
        }
        resolve("success");
      });
    },
    // 获取到回答的数据
    getAnswer(answerItem) {
      this.secureQuestionAnswers = this.secureQuestionAnswers.map((item) => {
        if (item.questionId === answerItem.questionId) {
          item = answerItem;
        }
        return item;
      });
    },
    // 选择当前问题
    selectQuestion(questionItem, index) {
      this.setUpSecurityQuestions[index].secureQA = {
        answer: "",
        providerId: "0",
        question: questionItem.question,
        questionId: questionItem.id,
      };
      this.setUpSecurityQuestions[index].showSelecter = false;
      this.$forceUpdate();
      this.handlerQuestionsList();
    },
    // 获取新设置的答案
    getNewAnswer(answerItem, index) {
      this.setUpSecurityQuestions[index].secureQA = answerItem;
      this.$forceUpdate();
    },
    // 关闭问题，重新选择问题
    closeQuestionBox(index) {
      this.setUpSecurityQuestions[index].secureQA = {};
      this.setUpSecurityQuestions[index].showSelecter = true;
      this.$forceUpdate();
      this.handlerQuestionsList();
    },
    // 每次操作问题后，处理问题列表的数据结构
    handlerQuestionsList() {
      // 同步刷新问题列表中被禁用的选项
      this.$nextTick(() => {
        let handlerQuestionsList = _.cloneDeep(this.listOfQuestions);
        // 先全部重置为未选中
        handlerQuestionsList = handlerQuestionsList.map((element) => {
          element.hasSelected = false;
          return element;
        });
        handlerQuestionsList = handlerQuestionsList.map((element) => {
          this.setUpSecurityQuestions.forEach((item) => {
            if (
              item.secureQA.questionId &&
              element.id === item.secureQA.questionId
            ) {
              element.hasSelected = true;
            }
          });
          return element;
        });
        this.listOfQuestions = handlerQuestionsList;
        this.$forceUpdate();
      });
    },
    // 验证完安全问题，可以进入下一步设置新的安全问题
    async startNextStep() {
      // 验证安全问题
      let params = {
        secureQuestionAnswers: this.secureQuestionAnswers.map((item) => {
          let answer = {};
          answer.questionId = item.questionId;
          answer.answer = item.answer;
          return answer;
        }),
        providerId: this.$store.state.accountStore.secureInfo.providerId,
      };
      const result = await checkAnswers(params);
      if (result && result.data.data) {
        // 验证成功则克隆老问题并清空表单
        this.oldData = _.cloneDeep(params.secureQuestionAnswers);
        // 已验证过安全问题
        this.verifiedOldQA = true;
        // 展示设置安全问题模块
        this.hasSetUpSecurityQA = false;
        // 这里重新获取一下新的没有选中状态的问题列表
        this.listOfQuestions = _.cloneDeep(
          this.$store.state.accountStore.secureInfo.allSecureQuestionAnswers
        );
        this.$store.commit(
          "setAccountSecurityTitle",
          this.$t("setNew") + this.$t("secureQuestion")
        );
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 点击提交
    async startSetUpNewQA() {
      if (!this.allowedSetUpNewQA) {
        return;
      }
      if (this.verifiedOldQA) {
        // 修改安全问题
        this.handlerSetSecuresQuestions("update");
      } else {
        // 首次设置安全问题
        this.handlerSetSecuresQuestions();
      }
    },
    // 设置安全问题
    async handlerSetSecuresQuestions(type = "add") {
      // 是否是强密码
      let hasLoginStrongPwd = this.$store.state.accountStore.secureInfo
        .hasLoginStrongPwd;
      const params = {
        newSecure: this.setUpSecurityQuestions.map((item) => item.secureQA),
        password: this.$store.state.accountSecurity.recordTheVerifiedPassword,
      };
      if (type === "update") {
        params.oldSecure = this.oldData;
      }
      const result = await modifyQuestion(params);
      if (result && result.code == 200) {
        if (result.data.data == true) {
          // // 设置完毕刷新安全信息
          if (hasLoginStrongPwd) {
            this.$store.commit("setAccountSecurityDialogStatus", false);
            this.$store.commit("setNeedShowAccountSecurityComponentName", "");
            this.$store.dispatch(
              "commitSetSecureInfo",
              this.$store.getters.userInfo.account ||
                this.$store.getters.userInfo.id
            );
            Bus.$emit("showPaymentMethod", "question");
          } else {
            // 如果不需要设置强密码则直接关闭弹窗
            // 如果验证完安全信息还需要输入密码的话，则进入密码验证窗口，主要用于支付功能
            if (this.$store.state.accountSecurity.changePassword) {
              this.$store.commit(
                "setNeedShowAccountSecurityComponentName",
                "password"
              );
            } else {
              this.$store.commit("setChangePassword", true);
              this.$store.commit("setAccountSecurityDialogStatus", false);
              this.$store.commit("setNeedShowAccountSecurityComponentName", "");
            }
          }
          this.$store.commit("setNeedGetUserinfoStatus", true);
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.questionSelecterStyle-enter-active, .questionSelecterStyle-leave-active
  transition: opacity .3s;
.questionSelecterStyle-enter,.questionSelecterStyle-leave-to
  opacity: 0;
.question__setting
  width 100%;
  overflow hidden;
  .inputAreaBox
    width 100%;
    overflow hidden;
    margin 20px 0;
    .inputOuterStyle
      margin-bottom 20px;
      .questionSelecter
        width 100%;
        height 50px;
        border-radius 4px;
        border 1px solid #8F8F8F;
        cursor pointer;
        user-select none;
        box-sizing border-box;
        padding 0 18px;
        display flex;
        align-items center;
        justify-content flex-start;
        .selecterLeft
          flex 1;
          min-width 0;
          height 50px;
          display flex;
          align-items center;
          justify-content flex-start;
          .questionIcon
            width 18px;
            height 18px;
            flex-shrink 0;
          .questionText
            flex 1;
            height 18px;
            box-sizing border-box;
            padding-left 10px;
            overflow hidden;
            text-overflow ellipsis;
            white-space: nowrap;
            font-size 14px;
            color #CCCCCC;
            user-select none;
        .selecterRight
          width 16px;
          height 100%;
          flex-shrink 0;
          display flex;
          align-items center;
          justify-content center;
          .iconStyle
            font-size 14px;
            color #CCCCCC;
    .nextStepButton
      width 100%;
      height 46px;
      margin-top 40px;
      border-radius 4px;
      overflow hidden;
      background #E6E6E6;
      display flex;
      align-items center;
      justify-content center;
      cursor not-allowed;
      .iconStyle
        color #FFFFFF;
        font-size 16px;
</style>
