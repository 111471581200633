<template>
  <div class="password-setting">
    <el-form
      :model="formData"
      :rules="rules"
      :show-message="false"
      ref="formData"
    >
      <el-form-item prop="newPassword">
        <el-input show-password v-model="formData.newPassword">
          <span
            slot="prepend"
            class="iconfont_Me icon-password"
            style="font-size:18px"
          ></span>
        </el-input>
      </el-form-item>
      <el-form-item prop="repPwd">
        <el-input show-password v-model="formData.repPwd">
          <span
            slot="prepend"
            class="iconfont_Me icon-password"
            style="font-size:18px"
          ></span>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          style="width:100%;height: 45px;"
          :type="through ? 'primary' : 'info'"
          @click.stop="
            () => {
              if (through) {
                handlerSubmitForm();
              }
            }
          "
        >
          <i class="iconfont_Me icon-check" style="font-size:20px" />
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { resetLoginPwd } from "../api";
import {
  currentLoginVipStatus,
  vip_update_password,
} from "@/api/newVersion/vip";
import { handlerEncrypt, handlerDecrypt } from "@/utils";
import { reapetToNum } from "@/utils";
export default {
  name: "Password",
  data() {
    // 验证新密码
    const validatePass = (rule, value, callback) => {
      if (!value) {
        this.through = false;
        callback(new Error(this.$t("nullPwd")));
      } else {
        const rex = new RegExp(
          /^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/
        );
        const _check = rex.test(value);
        if (!_check) {
          this.through = false;
          callback(new Error(this.$t("passweak")));
        } else {
          this.$refs["formData"].validateField("repPwd", (errorMessage) => {
            if (!errorMessage) {
              this.through = true;
              callback();
            } else {
              this.through = false;
              callback();
            }
          });
        }
      }
    };
    // 验证重复输入的新密码
    const validateReply = (rule, value, callback) => {
      if (!value) {
        this.through = false;
        callback(new Error(" "));
      } else {
        const rex = new RegExp(
          /^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/
        );
        const _check = rex.test(value);
        if (!_check) {
          this.through = false;
          callback(new Error(this.$t("passweak")));
        } else {
          if (
            this.formData.newPassword !== "" &&
            value === this.formData.newPassword
          ) {
            this.through = true;
            callback();
          } else if (
            this.formData.newPassword !== "" &&
            value !== this.formData.newPassword
          ) {
            this.through = false;
            callback(new Error(this.$t("pwdNotSame")));
          } else {
            this.through = false;
            callback();
          }
        }
      }
    };
    return {
      formData: {
        newPassword: "",
        repPwd: "",
      },
      through: false,
      rules: {
        newPassword: { validator: validatePass, trigger: "change" },
        repPwd: { validator: validateReply, trigger: "change" },
      },
    };
  },
  methods: {
    // 验证是否填写完毕
    // 提交修改密码的信息
    async handlerSubmitForm() {
      /**
       * 1.重置登录密码，判断如果有私钥，则用新的密码加密私钥上传服务器
       *  */
      const cLoginVipStatus = await currentLoginVipStatus();
      if (cLoginVipStatus.code == 200) {
        let ePrivateKey = cLoginVipStatus.data.data.privateKey;
        if (ePrivateKey) {
          this.updatePrivateKeyAndLogOut(ePrivateKey);
        } else {
          this.logOut();
        }
      } else {
        this.logOut();
      }
    },
    updatePrivateKeyAndLogOut(ePrivateKey) {
      const cRecordPassword = this.$store.state.vip.recordPassword;
      let keyStr = reapetToNum(cRecordPassword, 16);
      let newKeyStr = reapetToNum(this.formData.newPassword, 16);
      let dPrivateKey = handlerDecrypt(ePrivateKey, keyStr);
      // 使用新密码进行AES对私钥进行加密
      const newAesEncryptPrivateKey = handlerEncrypt(dPrivateKey, newKeyStr);
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          const params = {
            oldPassword: this.$store.state.accountSecurity
              .recordTheVerifiedPassword,
            newPassword: this.fnencrypt(this.formData.newPassword),
            privateKey: newAesEncryptPrivateKey,
          };
          let result = await vip_update_password(params);
          if (result.code == 200) {
            this.$store.commit("setCloseDialogAfterChangingThePassword", true);
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        } else {
          console.log("error");
        }
      });
    },
    logOut() {
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          const params = {
            oldPassword: this.$store.state.accountSecurity
              .recordTheVerifiedPassword,
            newPassword: this.fnencrypt(this.formData.newPassword),
            userId: this.$store.state.accountStore.secureInfo.providerId,
          };
          let result = await resetLoginPwd(params);
          if (result.code == 200) {
            this.$store.commit("setVipModeStatus", false);
            this.$store.commit("setAccountSecurityDialogStatus", false);
            this.$store.commit("setCloseDialogAfterChangingThePassword", true);
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        } else {
          console.log("error");
        }
      });
    },
  },
};
</script>
