<script>
import { savePayPassword } from "@/api/newVersion/vip";
export default {
  name: "paymentPassword",
  data() {
    // 验证新密码
    const validatePass = (rule, value, callback) => {
      if (!value) {
        this.through = false;
        callback(new Error(this.$t("nullPwd")));
      } else {
        const rex = new RegExp(
          /^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/
        );
        const _check = rex.test(value);
        if (!_check) {
          this.through = false;
          callback(new Error(this.$t("passweak")));
        } else {
          this.$refs["formData"].validateField("repPwd", (errorMessage) => {
            if (!errorMessage) {
              this.through = true;
              callback();
            } else {
              this.through = false;
              callback();
            }
          });
        }
      }
    };
    // 验证重复输入的新密码
    const validateReply = (rule, value, callback) => {
      if (!value) {
        this.through = false;
        callback(new Error(" "));
      } else {
        const rex = new RegExp(
          /^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/
        );
        const _check = rex.test(value);
        if (!_check) {
          this.through = false;
          callback(new Error(this.$t("passweak")));
        } else {
          if (
            this.formData.newPassword !== "" &&
            value === this.formData.newPassword
          ) {
            this.through = true;
            callback();
          } else if (
            this.formData.newPassword !== "" &&
            value !== this.formData.newPassword
          ) {
            this.through = false;
            callback(new Error(this.$t("pwdNotSame")));
          } else {
            this.through = false;
            callback();
          }
        }
      }
    };
    return {
      formData: {
        newPassword: "",
        repPwd: "",
      },
      through: false,
      rules: {
        newPassword: { validator: validatePass, trigger: "change" },
        repPwd: { validator: validateReply, trigger: "change" },
      },
    };
  },
  methods: {
    // 验证是否填写完毕
    // 提交设置密码的信息
    async handlerSubmitForm() {
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          const params = {
            password: this.formData.newPassword,
            confirmPassword: this.formData.repPwd,
          };
          const body = this.getPostParams(
            params,
            this.$store.state.auth.auth.randomKey
          );
          const result = await savePayPassword(
            body,
            this.$store.state.auth.auth.token
          );
          if (result && result.code == 200 && result.message == "success") {
            // 设置完毕刷新安全信息
            await this.$store.dispatch(
              "commitSetSecureInfo",
              this.$store.getters.userInfo.account ||
                this.$store.getters.userInfo.id
            );
            this.$store.commit("setAccountSecurityDialogStatus", false);
            this.$store.commit("setNeedShowAccountSecurityComponentName", "");
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        } else {
          console.log("error");
        }
      });
    },
  },
  render() {
    return (
      <div class="password-setting">
        <el-form
          props={{
            model: this.formData,
            rules: this.rules,
            showMessage: false,
          }}
          ref="formData"
        >
          <div class="passwordTips">{this.$t("setPasswordTips")}</div>
          <el-form-item prop="newPassword" class="firstInputBox">
            <el-input show-password v-model={this.formData.newPassword}>
              <span slot="prepend" class="iconfont_Me icon-password"></span>
            </el-input>
          </el-form-item>
          <el-form-item prop="repPwd" class="secondInputBox">
            <el-input show-password v-model={this.formData.repPwd}>
              <span slot="prepend" class="iconfont_Me icon-password"></span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type={this.through ? "primary" : "info"}
              onClick={() => {
                if (this.through) {
                  this.handlerSubmitForm();
                }
              }}
            >
              <span class="iconfont_Me icon-check"></span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    );
  },
};
</script>
<style lang="stylus" scoped>
.passwordTips
  font-size 14px;
  color #8F8F8F;
  transform translate3d(0px, -20px, 0px);
  word-break: keep-all;
.firstInputBox
  margin-top 20px;
.secondInputBox
  margin-bottom 60px;
</style>
<style lang="stylus">
.password-setting
  .el-button--info
    background-color #E6E6E6 !important;
    border none !important;
  .el-button
    width 100%
</style>
