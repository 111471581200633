var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "question__setting" }, [
    _vm.hasSetUpSecurityQA
      ? _c(
          "div",
          { staticClass: "inputAreaBox" },
          [
            _vm._l(_vm.secureQuestionAnswers, function(item) {
              return _c(
                "div",
                { key: item.questionId, staticClass: "inputOuterStyle" },
                [
                  _c("QAInput", {
                    attrs: { questionItem: item },
                    on: { getAnswer: _vm.getAnswer }
                  })
                ],
                1
              )
            }),
            _c(
              "div",
              {
                staticClass: "nextStepButton",
                class: _vm.allowedNextStep ? "commomButtonHover" : "",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.startNextStep.apply(null, arguments)
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-next iconStyle" })]
            )
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "inputAreaBox" },
          [
            _vm._l(_vm.setUpSecurityQuestions, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "inputOuterStyle" },
                [
                  item.showSelecter
                    ? _c(
                        "QuestionListPopover",
                        {
                          attrs: {
                            handlerQuestionsList: _vm.listOfQuestions,
                            selecterIndex: index
                          },
                          on: { selectQuestion: _vm.selectQuestion }
                        },
                        [
                          _c("div", { staticClass: "questionSelecter" }, [
                            _c("div", { staticClass: "selecterLeft" }, [
                              _c(
                                "div",
                                { staticClass: "questionIcon" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "18px",
                                      height: "18px"
                                    },
                                    attrs: {
                                      src: require("@/assets/images/newLogin/questionIconLarge.png"),
                                      fit: "fill"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "questionText" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("setAnswer")) +
                                    "\n            "
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "selecterRight" }, [
                              _c("span", {
                                staticClass: "iconfont_Me icon-down iconStyle"
                              })
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  !item.showSelecter
                    ? _c("QAInput", {
                        attrs: {
                          needCloseQuestion: true,
                          questionItem: item.secureQA,
                          autoFocus: !item.showSelecter,
                          indexInParent: index
                        },
                        on: {
                          getAnswer: _vm.getNewAnswer,
                          closeQuestionBox: _vm.closeQuestionBox
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            _c(
              "div",
              {
                staticClass: "nextStepButton",
                class: _vm.allowedSetUpNewQA ? "commomButtonHover" : "",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.startSetUpNewQA.apply(null, arguments)
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
            )
          ],
          2
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }