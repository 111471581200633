<template>
  <div class="setting_email">
    <div class="inputAreaBox">
      <div class="inputOuter">
        <CommonInput
          :readOnly="disabled"
          @inputChange="inputEmail"
          :defaultInputText="formData.email"
        >
          <el-image
            style="width: 16px; height: 13px"
            :src="require('@/assets/images/newLogin/emailIcon.png')"
            fit="fill"
          ></el-image>
        </CommonInput>
      </div>
      <div class="inputOuter verificationCodeBox">
        <div class="leftInputBox">
          <CommonInput
            @inputChange="verificationCode"
            :defaultInputText="formData.code"
          >
            <el-image
              style="width: 14px; height: 16px"
              :src="require('@/assets/images/newLogin/securityIcon.png')"
              fit="fill"
            ></el-image>
          </CommonInput>
        </div>
        <div
          class="rightSendButton"
          :class="timeOut ? 'notAllowedStyle' : ''"
          @click.stop="handlerGetVerCode"
        >
          <el-image
            v-if="!timeOut"
            style="width: 16px; height: 16px"
            :src="require('@/assets/images/newLogin/sendIcon.png')"
            fit="fill"
          ></el-image>
          <span v-else class="countdownStyle">{{ time }}S</span>
        </div>
      </div>
    </div>
    <el-button
      class="submitEmailCodeButton"
      :class="allowed ? 'commomButtonHover' : ''"
      @click="allowed ? handlerSubmitForm() : ''"
      :loading="loading"
    >
      <i class="iconfont_Me icon-check iconStyle"></i>
    </el-button>
  </div>
</template>
<script>
import {
  sendValidateCode,
  modifyEmail,
  bindEmail,
  securityCheck,
} from "../api";
import Bus from "@/utils/bus.js";
import CommonInput from "@/newVersion/components/inputComponent/commonInput";
export default {
  name: "Email",
  components: {
    CommonInput,
  },
  data() {
    return {
      formData: {
        email: "",
        code: "",
      },
      time: 60,
      timeOut: false,
      disabled: false,
      oldData: {},
      stepCount: false,
      timeInter: null,
      loading: false,
    };
  },
  created() {
    const email = this.$store.state.accountStore.secureInfo.secureEmail;
    const start = email.indexOf("-") >= 0 ? email.indexOf("-") + 1 : 0;
    this.formData.email = email.slice(start, email.length);
    this.disabled = this.formData.email ? true : false;
  },
  destroyed() {
    this.resetTimer();
    this.stepCount = false;
  },
  computed: {
    allowed() {
      return this.formData.email != "" && this.formData.code != "";
    },
  },
  methods: {
    // 输入邮箱事件
    inputEmail(text) {
      this.formData.email = text;
    },
    // 输入验证码事件
    verificationCode(text) {
      this.formData.code = text;
    },
    // 发送邮箱验证码
    async handlerGetVerCode() {
      if (!this.formData.email) {
        this.$message({
          type: "error",
          message: "The cell email number cannot be empty",
        });
        return;
      }
      if (!this.timeOut) {
        this.handlerTiming();
        const params = {
          email: this.formData.email,
        };
        await sendValidateCode(params);
      }
    },
    // 开始倒计时
    handlerTiming() {
      this.timeOut = true;
      this.timeInter = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          this.resetTimer();
        }
      }, 1000);
    },
    // 重置倒计时
    resetTimer() {
      // 重置倒计时数据
      this.time = 60;
      // 可以点击下一次发送验证码
      this.timeOut = false;
      // 重置定时器
      window.clearInterval(this.timeInter);
    },
    // 点击提交按钮
    async handlerSubmitForm() {
      // 是否是强密码
      let hasLoginStrongPwd = this.$store.state.accountStore.secureInfo
        .hasLoginStrongPwd;
      this.loading = true;
      // 如果是禁止输入状态，则说明是在验证当前已绑定的邮箱
      if (this.disabled) {
        // 验证邮箱，将已绑定的邮箱储存起来，用于绑定新邮箱的时候判断一下是否输入了相同的邮箱
        this.oldData = {
          oldEmail: this.formData.email,
          oldCode: this.formData.code,
        };
        const params = {};
        params.code = this.formData.code;
        params.codeType = "2";
        params.providerId = this.$store.state.accountStore.secureInfo.providerId;
        const result = await securityCheck(params);
        if (result && result.code === 200 && result.data.data !== false) {
          this.formData = {
            email: "",
            code: "",
          };
          // 加载动画关闭
          this.loading = false;
          // 不再需要验证邮箱
          this.disabled = false;
          // 可以修改邮箱
          this.stepCount = true;
          this.$forceUpdate();
          this.$store.commit(
            "setAccountSecurityTitle",
            this.$t("setNew") + this.$t("email")
          );
          this.resetTimer();
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          this.formData.code = "";
          this.loading = false;
        }
      } else {
        let result;
        // 验证过老邮箱过后进入这一步
        if (this.stepCount) {
          if (this.formData.email == this.oldData.oldEmail) {
            this.$message({
              type: "error",
              message: this.$t("sameAsTheOldMailbox"),
            });
            this.loading = false;
            return;
          }
          // 修改邮箱
          const params = {
            ...this.oldData,
            newEmail: this.formData.email,
            newCode: this.formData.code,
          };
          result = await modifyEmail(params);
        } else {
          // 首次绑定邮箱
          const params = {
            ...this.formData,
            password: this.$store.state.accountSecurity
              .recordTheVerifiedPassword,
          };
          result = await bindEmail(params);
        }
        this.loading = false;
        this.resetTimer();
        if (result && result.code == 200) {
          if (result.data.data == true) {
            // 如果是強密碼，綁定邮箱后，直接进入支付方式页面
            if (hasLoginStrongPwd) {
              this.$store.commit("setAccountSecurityDialogStatus", false);
              this.$store.commit("setNeedShowAccountSecurityComponentName", "");
              this.$store.dispatch(
                "commitSetSecureInfo",
                this.$store.getters.userInfo.account ||
                  this.$store.getters.userInfo.id
              );
              Bus.$emit("showPaymentMethod", "email");
            } else {
              // 如果不需要设置强密码则直接关闭弹窗
              // 如果验证完安全信息还需要输入密码的话，则进入密码验证窗口，主要用于支付功能
              if (this.$store.state.accountSecurity.changePassword) {
                this.$store.commit(
                  "setNeedShowAccountSecurityComponentName",
                  "password"
                );
              } else {
                this.$store.commit("setChangePassword", true);
                this.$store.commit("setAccountSecurityDialogStatus", false);
                this.$store.commit(
                  "setNeedShowAccountSecurityComponentName",
                  ""
                );
              }
            }
            this.$store.commit("setNeedGetUserinfoStatus", true);
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.setting_email
  padding-bottom 40px;
  .inputAreaBox
    width 100%;
    overflow hidden;
    margin 20px 0 50px 0;
    min-height 110px;
    .inputOuter
      width 100%;
      height 46px;
      overflow hidden;
      margin-bottom 18px;
      &:last-child
        margin-bottom 0;
      .iconStyle
        color #666666;
        font-size 20px;
    .verificationCodeBox
      display flex;
      align-items center;
      justify-content flex-start;
      .leftInputBox
        width 275px;
        height 100%;
        flex-shrink 0;
      .notAllowedStyle
        cursor not-allowed !important;
        &:hover
          background transparent !important;
        .countdownStyle
          color #33CC66;
          font-size 14px;
      .rightSendButton
        flex 1;
        height 100%;
        margin-left 5px;
        box-sizing border-box;
        border 1px solid #33CC66;
        border-radius 4px;
        user-select none;
        cursor pointer;
        display flex;
        align-items center;
        justify-content center;
        transition background .3s;
        &:hover
          background #F2FCF8;
  .submitEmailCodeButton
    width 100%;
    height 46px;
    margin-top 50px;
    border-radius 4px;
    overflow hidden;
    background #E6E6E6;
    display flex;
    align-items center;
    justify-content center;
    cursor not-allowed;
    .iconStyle
      color #FFFFFF;
      font-size 16px;
</style>
<style lang="stylus">
.setting_email
  .el-input-group__append
    background #3c6 !important;
    color: #ffffff !important;
    padding 0 !important;
    span
      display: flex;
      width: 111px;
      height: 38px;
      cursor pointer;
      align-items center;
      justify-content center;
</style>
