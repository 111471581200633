<template>
  <div class="setting__phone">
    <div class="inputAreaBox" v-if="hasSetUpSecurityPhone">
      <div class="inputOuter">
        <CommonInput
          :readOnly="true"
          :defaultInputText="
            `+${$store.state.accountStore.secureInfo.securePhone}`
          "
        >
          <el-image
            style="width: 11px; height: 16px"
            :src="require('@/assets/images/newLogin/phoneIcon.png')"
            fit="fill"
          ></el-image>
        </CommonInput>
      </div>
      <div class="inputOuter verificationCodeBox">
        <div class="leftInputBox">
          <CommonInput @inputChange="verificationCodeForOldPhone">
            <el-image
              style="width: 14px; height: 16px"
              :src="require('@/assets/images/newLogin/securityIcon.png')"
              fit="fill"
            ></el-image>
          </CommonInput>
        </div>
        <div
          class="rightSendButton"
          :class="hasSendVerificationCode ? 'notAllowedStyle' : ''"
          @click.stop="sendVerificationCodeForOldPhone"
        >
          <el-image
            v-if="!hasSendVerificationCode"
            style="width: 16px; height: 16px"
            :src="require('@/assets/images/newLogin/sendIcon.png')"
            fit="fill"
          ></el-image>
          <span v-else class="countdownStyle">{{ countdownText }}S</span>
        </div>
      </div>
      <div
        class="nextStepButton"
        :class="allowedNextStep ? 'commomButtonHover' : ''"
        @click.stop="startNextStep"
      >
        <span class="iconfont_Me icon-next iconStyle"></span>
      </div>
    </div>
    <div class="inputAreaBox" v-else>
      <VerifyPhone
        @selectThisAreaCode="selectThisAreaCode"
        @getInputText="getNewPhoneNumberText"
        @getVerificationText="verificationCodeForNewPhone"
      ></VerifyPhone>
      <div
        class="nextStepButton"
        :class="allowedEditPhone ? 'commomButtonHover' : ''"
        @click.stop="modifyOrBindMobilePhoneNumber"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { sendValidateCode, modifyPhone, bindPhone } from "../api";
import Bus from "@/utils/bus.js";
import CommonInput from "@/newVersion/components/inputComponent/commonInput";
import AddressSelectorForPhone from "@/newVersion/components/addressSelector/addressSelectorForPhone";
import VerifyPhone from "@/newVersion/components/verifyPhone/index.vue";
export default {
  name: "Phone",
  components: {
    CommonInput,
    AddressSelectorForPhone,
    VerifyPhone,
  },
  data() {
    return {
      // 已发送旧手机号的验证码
      hasSendVerificationCode: false,
      // 发送倒计时
      countdownText: 0,
      // 倒计时定时器对象
      countdownTimer: null,
      // 输入的旧手机号验证码文本
      verificationTextForOldPhone: "",
      // 用于存储已验证过的旧手机号
      oldData: {},
      // 用于记录是否验证过旧手机号，如果验证过旧手机号，需要传入接口需要传入旧手机号
      verifiedOldPhoneNumber: false,
      // 需要绑定的新手机号
      newPhoneNumberText: "",
      // 输入的新手机号验证码文本
      verificationTextForNewPhone: "",
      // 新手机号的区号
      countryCodeForNewPhone: "86",
      // 设置过安全手机
      hasSetUpSecurityPhone: false,
    };
  },
  created() {
    // 判断是否设置过安全手机
    let phone = this.$store.state.accountStore.secureInfo.securePhone;
    if (phone !== "") {
      this.hasSetUpSecurityPhone = true;
      this.countryCodeForNewPhone = this.$store.state.accountSecurity.securityPhoneAreaCode;
    }
  },
  watch: {
    // 监听已发送旧手机号的验证码,开始倒计时
    hasSendVerificationCode: {
      handler(val, old) {
        if (val && val !== old) {
          clearTimeout(this.countdownTimer);
          this.countdownText = 60;
          this.handleCountdown();
        }
      },
      deep: true,
    },
  },
  computed: {
    // 是否输入完旧手机号的验证码
    allowedNextStep() {
      return this.globalTrim(this.verificationTextForOldPhone) !== "";
    },
    // 是否输入完旧手机号的验证码
    allowedEditPhone() {
      return (
        this.globalTrim(this.verificationTextForNewPhone) !== "" &&
        this.globalTrim(this.newPhoneNumberText) !== "" &&
        /^[0-9]*$/.test(this.globalTrim(this.newPhoneNumberText)) &&
        this.globalTrim(this.countryCodeForNewPhone) !== ""
      );
    },
  },
  methods: {
    // 选择区号
    selectThisAreaCode(areaCode) {
      this.countryCodeForNewPhone = areaCode;
    },
    // 获取旧手机号的验证码
    verificationCodeForOldPhone(text) {
      this.verificationTextForOldPhone = text;
    },
    // 获取新手机号的验证码
    verificationCodeForNewPhone(text) {
      this.verificationTextForNewPhone = text;
    },
    // 获取新手机号文本
    getNewPhoneNumberText(text) {
      this.newPhoneNumberText = text;
    },
    // 点击获取旧手机号的验证码
    async sendVerificationCodeForOldPhone() {
      // 如果还在倒计时中，就不能再发送
      if (this.hasSendVerificationCode) {
        return;
      }
      try {
        let sendData = this.$store.state.accountStore.secureInfo.securePhone.split(
          "-"
        );
        let params = {
          countryCode: sendData[0],
          phone: sendData[1],
        };
        let result = await sendValidateCode(params);
        if (result.code === 200) {
          this.hasSendVerificationCode = true;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } catch (e) {
        console.log(this.$store.state.accountStore.secureInfo.securePhone);
      }
    },
    // 倒计时计算操作
    handleCountdown() {
      this.countdownTimer = setTimeout(() => {
        if (this.countdownText > 1) {
          this.countdownText--;
          this.handleCountdown();
        } else {
          clearTimeout(this.countdownTimer);
          this.hasSendVerificationCode = false;
        }
      }, 1000);
    },
    // 检查验证码是否正确，正确的话就进入下一步
    async startNextStep() {
      if (this.allowedNextStep) {
        let sendData = this.$store.state.accountStore.secureInfo.securePhone.split(
          "-"
        );
        this.oldData = {
          oldPhone: sendData[1],
          oldCode: this.globalTrim(this.verificationTextForOldPhone),
        };
        this.$store.commit(
          "setAccountSecurityTitle",
          this.$t("edit") + this.$t("phone")
        );
        // 标记已验证过旧手机号
        this.verifiedOldPhoneNumber = true;
        clearTimeout(this.countdownTimer);
        this.countdownText = 60;
        this.hasSendVerificationCode = false;
        this.$nextTick(() => {
          // 展示设置安全手机号模块
          this.hasSetUpSecurityPhone = false;
        });
      }
    },
    // 修改手机号或绑定手机号
    async modifyOrBindMobilePhoneNumber() {
      // 是否是强密码
      let hasLoginStrongPwd = this.$store.state.accountStore.secureInfo
        .hasLoginStrongPwd;
      if (!this.allowedEditPhone) {
        return;
      }
      let result;
      // 已验证过旧手机号
      if (this.verifiedOldPhoneNumber) {
        if (this.newPhoneNumberText === this.oldData.oldPhone) {
          this.$message({
            type: "error",
            message: this.$t("sameAsTheOldPhone"),
          });
          return;
        }
        // 修改手机号
        const params = {
          ...this.oldData,
          newPhone: this.newPhoneNumberText,
          newCode: this.verificationTextForNewPhone,
          countryCode: this.countryCodeForNewPhone,
        };
        result = await modifyPhone(params);
      } else {
        // 首次绑定
        const params = {
          phone: this.newPhoneNumberText,
          code: this.verificationTextForNewPhone,
          countryCode: this.countryCodeForNewPhone,
          password: this.$store.state.accountSecurity.recordTheVerifiedPassword,
        };
        result = await bindPhone(params);
      }
      if (result && result.code === 200) {
        if (result.data.data === true) {
          if (hasLoginStrongPwd) {
            this.$store.commit("setAccountSecurityDialogStatus", false);
            this.$store.commit("setNeedShowAccountSecurityComponentName", "");
            this.$store.dispatch(
              "commitSetSecureInfo",
              this.$store.getters.userInfo.account ||
                this.$store.getters.userInfo.id
            );
            Bus.$emit("showPaymentMethod", "phone");
          } else {
            // 如果不需要设置强密码则直接关闭弹窗
            // 如果验证完安全信息还需要输入密码的话，则进入密码验证窗口，主要用于支付功能
            if (this.$store.state.accountSecurity.changePassword) {
              this.$store.commit(
                "setNeedShowAccountSecurityComponentName",
                "password"
              );
            } else {
              this.$store.commit("setChangePassword", true);
              this.$store.commit("setAccountSecurityDialogStatus", false);
              this.$store.commit("setNeedShowAccountSecurityComponentName", "");
            }
          }
          // 设置完毕刷新安全信息
          this.$store.commit("setNeedGetUserinfoStatus", true);
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.setting__phone
  .inputAreaBox
    width 100%;
    overflow hidden;
    margin 20px 0;
    min-height 110px;
    .inputOuter
      width 100%;
      height 46px;
      overflow hidden;
      margin-bottom 18px;
      &:last-child
        margin-bottom 0;
      .iconStyle
        color #666666;
        font-size 20px;
  .verificationCodeBox
    display flex;
    align-items center;
    justify-content flex-start;
    .leftInputBox
      width 275px;
      height 100%;
      flex-shrink 0;
    .notAllowedStyle
      cursor not-allowed !important;
      &:hover
        background transparent !important;
      .countdownStyle
        color #33CC66;
        font-size 14px;
    .rightSendButton
      flex 1;
      height 100%;
      margin-left 5px;
      box-sizing border-box;
      border 1px solid #33CC66;
      border-radius 4px;
      user-select none;
      cursor pointer;
      display flex;
      align-items center;
      justify-content center;
      transition background .3s;
      &:hover
        background #F2FCF8;
  .nextStepButton
    width 100%;
    height 46px;
    margin-top 50px;
    border-radius 4px;
    overflow hidden;
    background #E6E6E6;
    display flex;
    align-items center;
    justify-content center;
    cursor not-allowed;
    .iconStyle
      color #FFFFFF;
      font-size 16px;
</style>
