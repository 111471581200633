<template>
  <div class="selection_method">
    <div class="tips">{{ $t("pleaseSetSecurity") }}</div>
    <div class="methods">
      <div
        v-for="(el, index) in Object.keys(items)"
        :key="index"
        class="methods__item"
      >
        <div class="settingAccount__item--left">
          <img
            :src="require(`@/assets/images/newSetting/${el}Icon.png`)"
            :style="{
              width: '20px',
              height: '20px',
            }"
          />
          <i v-if="items[el].success" class="el-icon--success"></i>
        </div>
        <div class="settingAccount__item--right">
          <span v-if="items[el].value" class="value">{{
            items[el].value
          }}</span>
          <i class="el-icon-edit" @click="handlerShowDialog(el)"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: {
        phone: { value: "", success: false },
        email: { value: "", success: false },
        question: {
          value: "",
          success: false,
        },
      },
      types: ["password", "phone", "email", "question"],
    };
  },
  async created() {
    await this.handlerGetUserinfo();
  },
  methods: {
    async handlerShowDialog(type) {
      if (!this.types.includes(type)) {
        return;
      }
      // 如果是设置登录密码，判断是否是第三方登录并且还没设置过强密码，默认是没设置过登录密码的三方用户
      if (
        type == "password" &&
        this.$store.getters.userInfo.loginState == "thirdPartyLogin" &&
        !this.$store.state.accountStore.secureInfo.hasLoginStrongPwd
      ) {
        await this.verifyThirdParty();
      } else {
        this.$store.commit("setAccountSecurityDialogStatus", true);
        this.$store.commit("setNeedShowAccountSecurityComponentName", type);
      }
    },
    // 获取用户安全信息
    handlerGetUserinfo() {
      return new Promise(async (resolve, reject) => {
        await this.$store.dispatch(
          "commitSetSecureInfo",
          this.$store.getters.userInfo.account ||
            this.$store.getters.userInfo.id
        );
        this.items.email.value = this.$store.state.accountStore.secureInfo.secureEmail;
        this.items.phone.value = this.$store.state.accountStore.secureInfo.securePhone;
        this.items.email.success = !!this.$store.state.accountStore.secureInfo
          .secureEmail;
        this.items.phone.success = !!this.$store.state.accountStore.secureInfo
          .securePhone;
        this.items.question.success = this.$store.state.accountStore.secureInfo.hasSecureQuestion;
        if (
          this.$store.state.accountStore.secureInfo.securePhone &&
          this.$store.state.accountStore.secureInfo.securePhone !== ""
        ) {
          try {
            this.$store.commit(
              "setSecurityPhoneAreaCode",
              this.$store.state.accountStore.secureInfo.securePhone.split(
                "-"
              )[0]
            );
          } catch (e) {}
        }
        resolve("success");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.selection_method {
  .tips {
    width: 100%;
    padding: 12px 20px;
    border-radius: 4px;
    color: #a1a1a1;
    background: #f9f9f9;
    box-sizing: border-box;
    word-break: keep-all;
  }

  .methods {
    margin-top: 40px;

    .methods__item {
      display: flex;
      justify-content: space-between;
      color: #666;
      padding: 1.5rem 0;
      border-top: 1px solid #f8f8f8;

      .settingAccount__item--right {
        cursor: pointer;
      }

      .settingAccount__item--left {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
