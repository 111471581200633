<template>
  <el-form :model="formData" :show-message="false" ref="formData">
    <el-form-item prop="password">
      <div
        class="checkPasswordTips"
        v-if="
          $store.state.accountSecurity
            .setNeedShowAccountSecurityComponentName == 'paymentPassword'
        "
      >
        <i class="el-icon-warning-outline"></i>
        {{ $t("forSecurityEnterLoginPwd") }}
      </div>
      <div class="inputOuter">
        <CommonInput
          @inputChange="getAccountText"
          :needInputshowError="inputError"
          :passwordInput="true"
        >
          <span class="iconfont_Me icon-a-lujing13297 iconStyle"></span>
        </CommonInput>
      </div>
    </el-form-item>
    <el-form-item>
      <el-button
        class="submitButton"
        :type="through ? 'primary' : 'info'"
        @click="
          () => {
            if (through) {
              handlerSubmitForm();
            }
          }
        "
      >
        <i class="el-icon--step-next" />
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { securityCheck } from "../api";
import CommonInput from "@/newVersion/components/inputComponent/commonInput";
export default {
  data() {
    return {
      formData: {
        password: "",
      },
      through: false,
      inputError: false,
      // 用于延迟检查
      timer: null,
    };
  },
  components: {
    CommonInput,
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    async handlerSubmitForm() {
      let params = {
        codeType: "4",
        password: this.fnencrypt(this.formData.password),
        providerId: this.$store.state.accountStore.secureInfo.providerId,
      };
      const result = await securityCheck(params);
      if (result && result.code === 200 && result.data.data) {
        this.through = true;
        this.inputError = false;
        // 存储登录密码,以便验证过后不需要再次验证
        this.$store.commit(
          "setRecordTheVerifiedPassword",
          this.fnencrypt(this.formData.password)
        );
        // 存储登录密码，以便后面解密私钥使用
        this.$store.commit("setRecordPassword", this.formData.password);
        // 将验证登录密码设置成否
        this.$store.commit("setNeedToVerifyThePassword", false);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        this.through = false;
        this.inputError = true;
      }
    },
    // 获取输入的文本
    async getAccountText(text) {
      this.formData.password = text;
      if (!this.formData.password) {
        this.through = false;
        this.inputError = false;
      } else {
        this.through = true;
        this.inputError = false;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.inputOuter
  width 100% !important;
  height 46px;
  margin-top 0px;
.submitButton
  margin 30px 0 0 0;
  width 100%
  height: 46px
.el-button--info{
  background-color #E6E6E6 !important;
  border-color #E6E6E6 !important;
}

.checkPasswordTips{
  color: red;
  transform: translate3d(0px, -30px, 0px);
  font-size: 14px;
  word-break: keep-all;
}
</style>
