var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "selection_method" }, [
    _c("div", { staticClass: "tips" }, [
      _vm._v(_vm._s(_vm.$t("pleaseSetSecurity")))
    ]),
    _c(
      "div",
      { staticClass: "methods" },
      _vm._l(Object.keys(_vm.items), function(el, index) {
        return _c("div", { key: index, staticClass: "methods__item" }, [
          _c("div", { staticClass: "settingAccount__item--left" }, [
            _c("img", {
              style: {
                width: "20px",
                height: "20px"
              },
              attrs: {
                src: require(`@/assets/images/newSetting/${el}Icon.png`)
              }
            }),
            _vm.items[el].success
              ? _c("i", { staticClass: "el-icon--success" })
              : _vm._e()
          ]),
          _c("div", { staticClass: "settingAccount__item--right" }, [
            _vm.items[el].value
              ? _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.items[el].value))
                ])
              : _vm._e(),
            _c("i", {
              staticClass: "el-icon-edit",
              on: {
                click: function($event) {
                  return _vm.handlerShowDialog(el)
                }
              }
            })
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }