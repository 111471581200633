var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "setting__dialog",
      class: !_vm.needToVerifyThePassword ? _vm.componentName : "",
      attrs: {
        visible: _vm.showDialog,
        width: "448px",
        "before-close": _vm.handleDialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", [_vm._v(_vm._s(_vm.dialogTitle))]),
        _vm.dialogTitle === _vm.$t("setNewLoginPassword")
          ? _c("div", { staticClass: "setNewPasswordTips" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("setPasswordTips")) + "\n    ")
            ])
          : _vm._e()
      ]),
      _vm.showCheckPassWordView
        ? _c("checkPwd")
        : _c(_vm.componentName, { ref: _vm.componentName, tag: "component" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }