var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setting_email" },
    [
      _c("div", { staticClass: "inputAreaBox" }, [
        _c(
          "div",
          { staticClass: "inputOuter" },
          [
            _c(
              "CommonInput",
              {
                attrs: {
                  readOnly: _vm.disabled,
                  defaultInputText: _vm.formData.email
                },
                on: { inputChange: _vm.inputEmail }
              },
              [
                _c("el-image", {
                  staticStyle: { width: "16px", height: "13px" },
                  attrs: {
                    src: require("@/assets/images/newLogin/emailIcon.png"),
                    fit: "fill"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "inputOuter verificationCodeBox" }, [
          _c(
            "div",
            { staticClass: "leftInputBox" },
            [
              _c(
                "CommonInput",
                {
                  attrs: { defaultInputText: _vm.formData.code },
                  on: { inputChange: _vm.verificationCode }
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "14px", height: "16px" },
                    attrs: {
                      src: require("@/assets/images/newLogin/securityIcon.png"),
                      fit: "fill"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "rightSendButton",
              class: _vm.timeOut ? "notAllowedStyle" : "",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.handlerGetVerCode.apply(null, arguments)
                }
              }
            },
            [
              !_vm.timeOut
                ? _c("el-image", {
                    staticStyle: { width: "16px", height: "16px" },
                    attrs: {
                      src: require("@/assets/images/newLogin/sendIcon.png"),
                      fit: "fill"
                    }
                  })
                : _c("span", { staticClass: "countdownStyle" }, [
                    _vm._v(_vm._s(_vm.time) + "S")
                  ])
            ],
            1
          )
        ])
      ]),
      _c(
        "el-button",
        {
          staticClass: "submitEmailCodeButton",
          class: _vm.allowed ? "commomButtonHover" : "",
          attrs: { loading: _vm.loading },
          on: {
            click: function($event) {
              _vm.allowed ? _vm.handlerSubmitForm() : ""
            }
          }
        },
        [_c("i", { staticClass: "iconfont_Me icon-check iconStyle" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }